import { useRef } from "react";

export function useScroll() {
  const htmlElRef = useRef(null);
  const executeScroll = (extraOffSet: number) =>
    window.scrollTo({
      behavior: "smooth",
      top: htmlElRef.current.offsetTop + (extraOffSet || 0),
    });

  return [executeScroll, htmlElRef];
}
