import React, { useState, useEffect } from "react";
import { Transition, CSSTransition } from "react-transition-group";

export interface IProps {
  title: string;
  desc: any;
  anchored?: boolean;
}
export default function Container(props: IProps) {
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    setOpened(props.anchored);
  }, [props.anchored]);

  return (
    <div className="body-item">
      <h2 className="title" onClick={() => setOpened(!opened)}>
        {opened ? (
          <i className="las la-angle-down"></i>
        ) : (
          <i className="las la-angle-right"></i>
        )}
        {props.title}
      </h2>
      <CSSTransition
        in={opened}
        timeout={500} // 필요 없는것 같은데.
        classNames="fade"
        unmountOnExit
      >
        <div className="fade">{props.desc}</div>
      </CSSTransition>
    </div>
  );
}
