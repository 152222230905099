import React, { useRef, useEffect } from "react";
import "./index.scss";
import Image from "../../image";
import Header from "../../Header";
import BodyItem from "./BodyItem";
import { useScroll } from "../../../utils/hooks";

interface IProps {
  anchor?: string;
}
export default function Container(props: IProps) {
  const [scrollToFeature, featureRef] = useScroll();

  // 특징&기능에 anchor를 적용해야할때
  useEffect(() => {
    if (props.anchor === "feature") {
      setTimeout(() => {
        (scrollToFeature as any)(-180);
      }, 1000);
    }
  }, []);

  return (
    <section className="container timechef">
      <div className="outline">
        <Image imgName="timechef-logo.png" className="logo invisible-mobile" />
        <div className="content">
          <h1 className="title">타임셰프 (TimeChef)</h1>
          <Image
            imgName="timechef-logo.png"
            className="logo invisible-desktop"
          />
          <h2 className="desc">
            라이프 로깅(Life Logging) 서비스입니다.
            <br />
            <br />
            <b>'집중 - 기록 - 회고'</b>라는 프로세스 제공함으로써 시간을 더
            소중하게 쓰고, 그 순간들을 남기며, 헛되게 지나가지 않도록 도와줍니다
          </h2>
          <div className="platform">
            <a
              href="https://play.google.com/store/apps/details?id=com.qus.timechef"
              target="_blank"
            >
              <Image imgName="badge-google-play.png" className="badge" />
            </a>
            <a
              href="https://apps.apple.com/kr/app/timechef/id1506801860?app=itunes&ign-mpt=uo%3D4"
              target="_blank"
            >
              <Image imgName="badge-ios-app-store.png" className="badge" />
            </a>
          </div>
        </div>
      </div>

      <div className="body">
        <BodyItem
          title={"타임셰프(TimeChef)?"}
          desc={
            <div className="desc-container">
              <div className="desc">
                타임셰프라는 이름은 이 앱을 만들게 된 계기가 된 인물과 관련되어
                있습니다.
                <br />
                <br />
                이 앱은 '시간을 정복한 남자 류비셰프'라는 책을 읽고, 매일의
                시간을 기록하고 회고하고 통계냈던 '류비셰프의 시간 통계법'에서
                영감을 받아 만들어졌습니다.
                <br />
                <div className="img-container">
                  <Image imgName="timechef-splash.png" className="img" />
                  <Image imgName="timechef-book.png" className="img" />
                </div>
                그래서 시간을 의미하는 '타임(time)'과, 류비셰프(Lyubishev)와
                발음이 같은 단어 '셰프(chef)'를 합쳐 '타임셰프(TimeChef)'라고
                이름 짓게되었습니다.
                <br />
                <br />
                의도하지는 않았지만, 당신의 시간을 더욱 맛있게 해주겠다는 식의
                해석도 가능합니다(...)
              </div>
            </div>
          }
        />

        <div ref={featureRef as any}></div>
        <BodyItem
          title={"특징 & 핵심기능"}
          anchored={props.anchor === "feature"}
          desc={
            <div className="desc-container">
              <div className="title">1. 입력은 최대한 단순하게</div>
              <div className="desc">
                대부분의 라이프 로깅 or 타임 트래킹 앱들이 기록을 입력할때
                동시에 카테고리도 함께 입력해야하는 방식을 사용합니다. (아니면
                카테고리 자체를 선택하든가)
                <br />
                하지만 내가 지금 하려는 행동이 어떤 범주에 들어가느냐 결정하는
                것은 생각보다 에너지가 드는 작업입니다.
                <br />
                카테고라이징이 시간기록을 번거롭고 까다롭게만드는 만드는 심리적
                장벽이 될 수 있습니다.
                <br />
                <br />
                그래서 가장 단순한 입력방법을 생각했습니다.
                <br />
                그리고 선택한 것은 가장 원시적이면서도 단순한 입력방식, '그냥
                적는 것'이었습니다.
                <br />
                <div className="img-container">
                  <Image imgName="timechef-input1.png" className="img" />
                  <Image imgName="timechef-input2.png" className="img" />
                </div>
                "밥먹기", "내일 발표 준비", "세미나 PPT만들기", "부모님
                결혼기념일 준비하기", "영어 공부" 처럼.
                <br />
                마치 다이어리에 적거나, 채팅앱으로 친구한테 뭘할 건지 알려주는
                것처럼 단어나 문장을 입력하는 것입니다.
                <br />
                (향후 버전에서는 음성입력 지원을 검토중입니다)
                <br />
                <br />
                더불어 이렇게 하면 우리의 시간을 더 구체적으로 기록할 수 있다는
                이점도 생깁니다.
                <br />
                (우리의 삶은 태그나 카테고리가 아니라 구체적인 순간들이기
                때문입니다.)
                <br />
                <br />
                물론 통계를 위해서는 태그를 다는 카테고라이징 작업은
                불가피합니다.
                <br />
                하지만 타임셰프에서는 '회고하기' 기능(뒤에서 자세히 소개)을
                제공함으로써 번거로운 분류작업을 하루를 돌아보는 시간으로
                자연스레 녹아들게 해 입력시의 번거로움을 최소화하고자 했습니다.
              </div>
              <div className="title">2. 하나에 집중할 수 있도록</div>
              <div className="desc">
                <Image imgName="timechef-inprogress.png" className="img" />
                무엇을 할 지 입력하고 나면 집중을 의미하는 화면으로 바뀌고{" "}
                <br />한 번에 하나의 활동만 입력하게 함으로써 한 가지에
                집중하는데 도움을 줄 수 있도록 했습니다.
              </div>
              <div className="title">3. 다이어리를 사용하는 듯한 느낌</div>
              <div className="desc">
                <Image imgName="timechef-record1.png" className="img" />
                내가 기록한 활동들을 마치 프랭클린 플래너같은 시스템 다이어리에
                기록한 것처럼 보여줌으로써 더 친숙하고 가독성을 높이고자
                했습니다.
              </div>
              <div className="title">4. 하루를 돌아볼 수 있게</div>
              <div className="desc">
                <Image imgName="timechef-review1.png" className="img" />
                매일의 기록 상단 '회고하기' 버튼을 통해 오늘 하루를 돌아 볼 수
                있는 프로세스를 제공합니다.
                <br />
                <br />
                <Image imgName="timechef-review2.png" className="img" />
                회고 화면으로 진입하면, 오늘 내가 했던 기록들을 시간순으로
                하나씩 볼 수 있습니다.
                <br />
                기록을 보며 오늘의 일들을 회상하면서, 부족하거나 잘 못 입력된
                기록은 수정할 수 있습니다.
                <br />
                (회고는 당일 저녁에 하는 것이 권장되지만, 나중에 시간날때 해도
                상관없습니다)
                <br />
                <br />
                <Image imgName="timechef-review3.png" className="img" />
                오늘의 기록들을 돌아보면서 '필요한 경우' 태그를 달 수 있습니다.
                <br />
                그러나 모든 기록에 태그를 달 필요는 없습니다.
                <br />
                태그를 기반으로 통계가 보여지므로 내가 분석하고 싶은 활동들(ex.
                공부, 운동, 잠 등)에 대해서만 달면 됩니다.
                <br />
                <br />
                <div className="img-container">
                  <Image imgName="timechef-review4.png" className="img" />
                  <Image imgName="timechef-review5.png" className="img" />
                </div>
                회고의 마지막 단계에서는 오늘에 대해 일기를 쓸 수 있는 기능도
                제공합니다.
                <br />
                <br />
                <div className="img-container">
                  <Image imgName="timechef-review6.png" className="img" />
                  <Image imgName="timechef-review7.png" className="img" />
                </div>
                회고를 모두 마치면 오늘 보낸 시간들에 대한 간단한 통계를
                보여줍니다.
                <br />더 자세한 통계는 '통계'메뉴에서 확인할 수 있습니다.(뒤에서
                자세히 소개)
              </div>
              <div className="title">5. 통계를 제공</div>
              <div className="desc">
                <div className="img-container">
                  <Image imgName="timechef-statistics1.png" className="img" />
                  <Image imgName="timechef-statistics2.png" className="img" />
                  <Image imgName="timechef-statistics3.png" className="img" />
                </div>
                통계 메뉴로 들어가면 '전체 태그'에 대한 통계, '특정 태그'에 대한
                통계를 '연간, 월간, 주간, 일간' 등 다양한 기간에 대하여
                제공합니다.
                <br />
                <br />
                기간 별로 내가 어떤 활동에 얼마만큼의 시간을 쓰는지
                가시적/직관적으로 알 수 있습니다.
              </div>
            </div>
          }
        />

        <BodyItem
          title={"Thanks to"}
          desc={
            <div className="desc-container">
              <div className="desc">
                앱의 이름을 류비셰프에게서 따오긴했지만, 사실 가장 크게 영향을
                받은 것은 '이정원 박사님'에게서 입니다.
                <br />
                <br />
                독서 커뮤니티 트레바리의 '내 인생의 조각모음' 클럽을 통해
                류비셰프의 존재를 알게 해주고, 책을 뛰어넘는 많은 사례와
                통찰력을 공유해주셨습니다.
                <br />
                <br />
                특별히 감사의 마음을 전합니다.
              </div>
            </div>
          }
        />
      </div>
    </section>
  );
}
