import React from "react";

import SEO from "../../components/seo";
import "../../styles/common/common.scss";
// line-awesome
import "line-awesome/dist/line-awesome/css/line-awesome.min.css";
import TimeChef from "../../components/_pages/TimeChef";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

import { ISEOProps } from "../../types/common";

import QueryString from "query-string";

const seoData: ISEOProps = {
  title: "타임셰프 (TimeChef)",
  description:
    "라이프 로깅(Life Logging) 서비스입니다. '집중 - 기록 - 회고'라는 프로세스 제공함으로써 시간을 더소중하게 쓰고, 그 순간들을 남기며, 헛되게 지나가지 않도록 도와줍니다.",
  keywords: ["타임셰프", "timㄷchef", "라이프 로깅", "life logging"],
  imageUrl: require("../../images/timechef/timechef-logo.png"),
};
function Container(props) {
  // ?anchor=feature에 대응
  const anchor = QueryString.parse(props.location.search).anchor as string;

  return (
    <div>
      <SEO {...seoData} />
      <Header />
      <TimeChef anchor={anchor} />
      <Footer />
    </div>
  );
}

export default Container;
