import React from "react";
import "./index.scss";
import Image from "../image";

export default function Container() {
  return (
    <header className="header">
      <a href="/">
        <Image imgName="favicon.png" className="logo" />
        <div className="title">
          개발자 <span className="strong">쿠스</span>가 만드는{" "}
          <span className="strong">타르트</span> 같은 프로그램들 모음
        </div>
      </a>
    </header>
  );
}
